export const OrgIds = {
  ylp: "bc11bb24-1f49-498b-83ab-b019ec2d815b",
  goodstoluck: "6c29378b-9582-434e-a036-20327e78d7ea",
  mannaflex: "3cec2028-d1cb-4428-b9ef-4df5a07be2e7",
  seonyeong: "05e3fd65-7167-40f8-b490-51aa1864bb77",
  seowon: "67f3b736-7c33-479e-af43-f6fc247e7f60",
  hangang: "020abc60-b727-4a3e-9e7d-0732e523f450",
  hangangPickUp: "84f73cfe-06c0-4f7c-9d19-4b1b46c44836",
  kdlogistics: "13b5d3a6-ada1-426a-aa3c-b06a22a178ae",
  today: "70000da1-900d-45d2-93cc-8dc9c8b35dc6",
  gobox: "318c76c8-95de-4904-8c3c-c90a4297774f",
  etc: "3d9047a1-d703-4a47-ac4a-6b6d94ac35b3",
} as const

export type OrgId = string | (typeof OrgIds)[keyof typeof OrgIds]

export interface Organization {
  id: OrgId
  name: string
  active: boolean
  type: "TURNKEY" | "LM" | "PICK_UP" | "FORWARDING" | ""
  stationIds: string[]
}
